<template>
  <div class="w-full" style="z-index: 1;">

    <div class="card bg-white mb-1 lg:sticky lg:top-0 gap-0" style="width:inherit; z-index: 1;">
      <div class="flex flex-col lg:flex-row gap-4 items-start">
        <h2 class="whitespace-nowrap w-full h-auto">
          Kalender &mdash; {{ capitalize(dateWritten(date)) }}
        </h2>

        <div class="mt-1 text-center justify-end">
          <UIInputDatePicker buttons v-model:date="date" />
        </div>

        <div class="flex flex-col sm:flex-row lg:flex-col justify-center lg:justify-start items-end gap-2 w-full">
          <div class="inline-flex flex-row items-center gap-2">
            <button v-if="store.getters.hasAdminPermission" class="btn indigo small" @click="() => toggleTab(tabs.WERKDRUK)">
              <i class="fas fa-clock"></i>
            </button>
            <router-link v-if="store.getters.hasAdminPermission" target="_blank" :to="{ name: 'RittenRendabiliteit', query: { date } }" class="btn bg-teal-400 text-white">
              <i class="fas fa-money-check-alt"></i>
            </router-link>
            <router-link v-if="store.getters.hasAdminPermission" target="_blank" :to="{ name: 'RittenVRP', query: { date } }" class="btn bg-orange-400 text-white">
              <i class="fas fa-route text-base"></i>
            </router-link>
            <router-link v-if="store.getters.hasAdminPermission" target="_blank" :to="{ name: 'PrintContracten', query: { date: date } }" class="btn purple">
              <i class="fas fa-file-contract text-base"></i>
            </router-link>
            <button class="btn green small" @click="refreshAll">
              <i class="fas fa-sync"></i>
            </button>
            <button v-if="store.getters.hasAdminPermission" class="btn yellow small" @click="showGeocodeStatus = !showGeocodeStatus">
              <i class="fas fa-globe-americas"></i>
            </button>
            <UIButtonCompact v-if="store.getters.hasAdminPermission" v-model="compact" class="small" />
          </div>

          <UICheckbox
            class="whitespace-nowrap"
            :label="`(${geannuleerdeRitten?.length}) Annulaties tonen`"
            v-model="annulatiesTonen"
          />
        </div>
      </div>

      <div class="flex flex-col lg:flex-row -mt-5" style="width: calc(100% - 200px)">
        <UILoading v-if="loadingAfwezigheden" sm />
        <UITableKalenderAfwezigheden v-else :afwezigheden="afwezigheden?.list"/>
      </div>
    </div>

    <div class="card w-full" v-if="tab === tabs.WERKDRUK">
      <TableWerkdruk :kalender="bevestigdeRitten" />
    </div>

    <template v-else>
      <div class="card w-full mb-1 lg:pt-24" v-if="store.getters.hasAdminPermission">
        <div class="grid grid-cols-2 lg:grid-cols-4 gap-2 py-2">
          <UIFormElement label="Chauffeur">
            <SelectChauffeur nullable v-model="filters.user_id" :prefix-items="[{ label: 'Niet toegekend', value: -1 }]" />
          </UIFormElement>
          <UIFormElement label="Wagen">
            <SelectWagen nullable active v-model="filters.wagen_id" />
          </UIFormElement>
          <UISelectFixed multiple type="KALENDER_FILTERS" placeholder="Alles tonen" v-model="filters.options" />
          <UIFormElement label="Zoeken">
            <input type="text" placeholder="Zoeken..." v-model="filters.search" @input="filters.search = $event?.target?.value || ''" />
          </UIFormElement>
        </div>
      </div>

      <div class="card w-full mb-1 relative overflow-x-auto">
        <small v-if="loadingItems" class="block text-center py-4">Dag {{ date }} aan het laden...</small>
        <UILoading v-if="loadingItems" sm />
        <TableKalender
          v-else
          :compact="compact"
          :show-geocode-status="showGeocodeStatus"
          :annulaties-tonen="annulatiesTonen"
          :reservaties="kalenderFiltered"
          :afspraken="afsprakenFiltered"
          @date="date = $event"
          @refresh-kalender="refreshKalender"
          @refresh-afspraken="refreshAfspraken"
        />
      </div>

      <div>
        <UIButtonNieuweRitToevoegen class="fixed bottom-5 right-5" :date="date" />
        <UIButtonNieuweAfspraakToevoegen class="fixed bottom-16 right-5" :date="date" />

        <small v-if="store.getters.hasAdminPermission && !loadingItems && !compact" class="text-xs font-normal flex flex-col mt-4">
          <h2 class="text-sm">{{ dateWritten(date) }}</h2>
          <span class="flex flex-row gap-2">
            <span class="whitespace-nowrap">Afspraken: {{ afspraken?.list?.length || 0 }}</span> &mdash;
            <span class="whitespace-nowrap">Ritten: {{ waardeRittenAantal }}</span>
          </span>
          <span class="flex flex-col gap-x-2">
            <span class="flex flex-col xl:flex-row gap-1 xl:items-center">
              <span class="whitespace-nowrap">Dagwaarde: {{ formatPrice(waardeDagTotaal + waardeAnnulatieKostenDagTotaal) }} EUR</span>
              <small class="whitespace-nowrap">(Annulatiekosten: {{ formatPrice(waardeAnnulatieKostenDagTotaal) }} EUR)</small>
            </span>
            <span class="whitespace-nowrap">Kilometers: {{ formatPrice(waardeKilometersTotaal) }}</span>
          </span>
        </small>
      </div>
    </template>

    <!--
    <details>
      <summary>KALENDER</summary>
      <pre>{{ kalender }}</pre>
    </details>
    <details>
      <summary>AFSPRAKEN</summary>
      <pre>{{ afspraken }}</pre>
    </details>
    <details>
      <summary>AFWEZIGHEDEN</summary>
      <pre>{{ afwezigheden }}</pre>
    </details>
    <details>
      <summary>WERKDAGENNAELKAAR</summary>
      <pre>{{ werkdagenNaElkaar }}</pre>
    </details>
    -->

  </div>
</template>

<script setup>
import { ref, computed, provide, onMounted, watch } from 'vue'

import useGetApi from '@/hooks/useGetApi'
import useRouteQueryDate from '@/hooks/useRouteQueryDate'
import { capitalize, lowercase } from '@/functions/formatText'
import { formatPrice } from '@/functions/formatNumber'
import { dateWritten } from '@/functions/formatDate'

import TableKalender from '@/components/Table/Kalender/Index.vue'
import TableWerkdruk from '@/components/Table/Werkdruk/Index.vue'
import SelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import SelectWagen from '@/components/UI/Select/Wagen.vue'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UIFormElement from '@/components/UI/Form/Element.vue'
import UIButtonCompact from '@/components/UI/Button/Compact.vue'
import UIButtonNieuweRitToevoegen from '@/components/UI/Button/NieuweRitToevoegen.vue'
import UIButtonNieuweAfspraakToevoegen from '@/components/UI/Button/NieuweAfspraakToevoegen.vue'
import UICheckbox from '@/components/UI/Input/Checkbox.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UITableKalenderAfwezigheden from '@/components/Table/Kalender/Afwezigheden.vue'
import UILoading from '@/components/UI/Loading.vue'
import { useStore } from 'vuex'

const store = useStore()
const date = useRouteQueryDate('date')
const compact = ref(false)
const showGeocodeStatus = ref(false)
const annulatiesTonen = ref(false)
const filters = ref({
  user_id: null,
  wagen_id: null,
  is_factuur_nodig: null,
  options: [],
  search: '',
})

const tabs = {
  KALENDER: 'KALENDER',
  WERKDRUK: 'WERKDRUK',
}
const tab = ref(tabs.KALENDER)
const toggleTab = (newTab) => {
  tab.value = tab.value === newTab ? tabs.KALENDER : newTab
}

const apiData = computed(() => ({ all: true, date: date.value }))
const apiDataAfwezigheden = computed(() => ({
  year: new Date(date.value).getFullYear(),
  month: new Date(date.value).getMonth() + 1,
  day: new Date(date.value).getDate(),
  status: 'ACCEPTED',
  roundDay: true,
}))
const apiDataWerkdagenNaElkaar = computed(() => ({
  date: date.value,
}))

// eslint-disable-next-line no-unused-vars
const { data: kalender, getData: refreshKalender, cancel: cancelKalender, loading: loadingKalender } = useGetApi('/api/dashboard/kalender/list', apiData, { watch: false })

// eslint-disable-next-line no-unused-vars
const { data: afspraken, getData: refreshAfspraken, cancel: cancelAfspraken, loading: loadingAfspraken } = useGetApi('AFSPRAAK_LIST', apiData, { watch: false })


const { data: afwezigheden, getData: refreshAfwezigheden, cancel: cancelAfwezigheden, loading: loadingAfwezigheden } = useGetApi('KALENDER_AFWEZIGHEID_LIST', apiDataAfwezigheden, { watch: false })
provide('afwezigheden', afwezigheden)

const { data: werkdagenNaElkaar, getData: refreshWerkdagenNaElkaar, cancel: cancelWerkdagenNaElkaar, loading: loadingWerkdagenNaElkaar } = useGetApi('KALENDER_WERKDAGEN_NA_ELKAAR_LIST', apiDataWerkdagenNaElkaar, { watch: false })

const loadingItems = computed(() => loadingKalender.value || loadingAfspraken.value || loadingWerkdagenNaElkaar.value)

const refreshAll = () => {
  refreshKalender()
  refreshAfspraken()
  refreshAfwezigheden()
  refreshWerkdagenNaElkaar()
}

const cancelAll = () => {
  cancelKalender()
  cancelAfspraken()
  cancelAfwezigheden()
  cancelWerkdagenNaElkaar()
}

onMounted(() => {
  watch([apiData, apiDataAfwezigheden, apiDataWerkdagenNaElkaar], () => {
    cancelAll()
    refreshAll()
  }, { immediate: true })
})

const isAfspraakToShow = item => {
  if (!item) return false

  if (filters.value.user_id == -1 && item.user_id > 0) {
    return false
  } else if (filters.value.user_id > 0 && item.user_id !== filters.value.user_id) {
    return false
  }

  for (const filterOptie of filters.value.options) {
    if (typeof filterOptie?.filterFn !== 'function') continue
    const shouldShow = filterOptie?.filterFn(item)
    if (!shouldShow) return false
  }

  if (filters.value.search) {
    const term = lowercase(filters.value.search).trim()
    return (
      lowercase(item.titel).includes(term)
      || lowercase(item.opmerking || '').includes(term)
      || lowercase((!item.adressen || typeof item.adressen === 'string' ? [] : item.adressen).map(el => el.adres || el).join(' ')).includes(term)
    )
  }

  return true
}

const afsprakenFiltered = computed(() => {
  return (afspraken.value?.list || []).filter(item => isAfspraakToShow(item))
})

const isKalenderToShow = item => {
  if (!item) return false

  if (filters.value.user_id == -1 && !!item.chauffeurs.find(x => x.chauffeur_id > 0)) {
    return false
  } else if (filters.value.user_id > 0 && !item.chauffeurs.find(x => x.chauffeur_id === filters.value.user_id)) {
    return false
  }

  if (filters.value.wagen_id && !item.chauffeurs.find(x => x.wagen_id === filters.value.wagen_id)) {
    return false
  }

  for (const filterOptie of filters.value.options) {
    if (typeof filterOptie?.filterFn !== 'function') continue
    const shouldShow = filterOptie?.filterFn(item)
    if (!shouldShow) return false
  }

  if (filters.value.search) {
    const term = lowercase(filters.value.search).trim()
    return (
      lowercase(item.klant?.firstname).includes(term)
      || lowercase(item.klant?.lastname).includes(term)
      || lowercase(item.klant?.phone).includes(term)
      || lowercase(item.klant?.email).includes(term)
      || lowercase(item.reiziger?.firstname).includes(term)
      || lowercase(item.reiziger?.lastname).includes(term)
      || lowercase(item.reiziger?.phone).includes(term)
      || lowercase(item.reiziger?.email).includes(term)
      || lowercase(item.rit?.vlucht).includes(term)
      || lowercase(item.rit?.promotie_code).includes(term)
      || lowercase(item.rit?.opmerking || '').includes(term)
      || lowercase(item.adressen?.map(el => el.adres).join(' ')).includes(term)
    )
  }

  return true
}

const kalenderFiltered = computed(() => {
  return (kalender.value?.list || []).map(item => {
    if (!item.chauffeurs?.length) {
      item.chauffeurs = [{ chauffeur_id: null, wagen_id: null, omzet: item.rit.calculated_omzet_chauffeur }]
    }
    return item
  }).filter(item => isKalenderToShow(item))
})

const bevestigdeRitten = computed(() => (kalender.value?.list || []).filter(el => el?.rit?.is_bevestigd))
const geannuleerdeRitten = computed(() => (kalender.value?.list || []).filter(el => el?.rit?.is_geannuleerd))
const waardeRittenAantal = computed(() => bevestigdeRitten.value.length)
const waardeDagTotaal = computed(() => bevestigdeRitten.value.reduce((total, next) => total + (next?.rit?.prijs || 0) / (next?.rit?.is_retour ? 2 : 1), 0))
const waardeAnnulatieKostenDagTotaal = computed(() => geannuleerdeRitten.value.reduce((total, next) => total + (next?.rit?.kosten_annulatie || 0) / (next?.rit?.kosten_annulatie ? 2 : 1), 0))
const waardeKilometersTotaal = computed(() => bevestigdeRitten.value.reduce((total, next) => total + next?.rit?.kilometers || 0, 0))

const formatLabelChauffeursToegekend = (item, originalLabel) => {
  if (!item?.value) {
    return originalLabel
  }
  if (item.value === -1) {
    const aantalRittenNietToegekend = bevestigdeRitten.value.reduce((prev, next) => {
      if (!next?.chauffeurs?.length || !next?.chauffeurs?.find(c => c.chauffeur_id > 0)) {
        return prev + 1
      }
      return prev
    }, 0)
    return `(${aantalRittenNietToegekend}) ${originalLabel}`
  }

  const aantalRitten = bevestigdeRitten.value.reduce((prev, next) => {
    if (next?.chauffeurs?.find(c => c.chauffeur_id === item.value)) {
      return prev + 1
    }
    return prev
  }, 0)

  return `(${aantalRitten}) ${originalLabel}`.trim()
}

const emojiNumbers = {
  0: '0️⃣',
  1: '1️⃣',
  2: '2️⃣',
  3: '3️⃣',
  4: '4️⃣',
  5: '5️⃣',
  6: '6️⃣',
  7: '7️⃣',
  8: '8️⃣',
  9: '9️⃣',
  10: '🔟',
}
const emojiCircle = {
  RED: '🔴',
  YELLOW: '🟡',
  ORANGE: '🟠',
  GREEN: '🟢',
}
const mapChauffeurWerkdagen = (chauffeur, originalLabel) => {
  const list = werkdagenNaElkaar?.value?.list || []
  let aantal = 0
  if (list?.length && chauffeur.value > 0) {
    aantal = list.find((x) => x.user_id === chauffeur.value)?.aantal || 0
    let status = emojiCircle.GREEN
    if (aantal > 3) status = emojiCircle.YELLOW
    if (aantal > 5) status = emojiCircle.ORANGE
    if (aantal > 7) status = emojiCircle.RED

    const ergensAangeduidVandaag = kalenderFiltered.value?.findIndex(record => record.chauffeurs.find(item => item.chauffeur_id === chauffeur.value)) > -1
    if (ergensAangeduidVandaag) {
      aantal += 1
    }

    aantal = Math.min(10, Math.max(0, aantal))

    return `🕓${emojiNumbers[aantal]}${status} ${originalLabel}`.trim()
  }

  return originalLabel
}

const formatLabelChauffeurs = (item, originalLabel) => {
  let newLabel = mapChauffeurWerkdagen(item, originalLabel)
  newLabel = formatLabelChauffeursToegekend(item, newLabel)
  return newLabel
}

provide('formatLabelChauffeurs', formatLabelChauffeurs)
</script>
