<template>
  <small v-if="toonGeenData" class="block text-center text-gray-600 py-4">
    Geen ritten gevonden op deze dag.
    <span v-show="aantalGeannuleerd > 0">({{ aantalGeannuleerd }} annulatie{{ aantalGeannuleerd === 1 ? '' : 's' }}
      verborgen)</span>
  </small>
  <div class="w-full overflow-x-scroll" v-else>

    <button class="btn info text-xs m-1 mt-0" @click="combinatiesKiezen = !combinatiesKiezen">
      <i class="fa fas fa-link"></i>
      Ritten combineren
    </button>

    <table class="w-full">
      <tbody class="whitespace-nowrap">
        <tr
          v-for="(record, recordIndex) in list"
          :key="`${record.id}${record.uuid}${record.rit?.id}`"
          v-show="!record?.rit?.is_geannuleerd || annulatiesTonen"
          class="w-full"
        >
          <td v-if="combinatiesKiezen" class="h-full">
            <UIInputCheckbox class="bg-gray-200 rounded-l p-1 -mr-1" v-model="combinatieKeuze[recordIndex]">
            </UIInputCheckbox>
          </td>
          <td class="w-full">
            <RowAfspraak
              v-if="record?.type === 'AFSPRAAK'"
              :key="`a-${record.touched}${record.uuid}${record.rit?.id}${recordIndex}`"
              class="mb-1 border-t-4 rounded-sm border-gray-300"
              :compact="compact"
              v-model="list[recordIndex]"
              @refresh="$emit('refresh-afspraken', $event)"
            />
            <RowKalender v-else-if="record?.type === 'KALENDER'"
              :key="`k-${record.touched}${record.uuid}${record.rit?.id}${recordIndex}`"
              :compact="compact"
              :show-geocode-status="showGeocodeStatus"
              v-model="list[recordIndex]"
              :flight-has-duplicate="flightHasDuplicate(record.rit?.vlucht, recordIndex)"
              @date="$emit('date', $event)" @refresh="$emit('refresh-kalender', $event)"
              @opslaan="handleOpslaan($event)" />
            <pre v-else :key="`${record?.uuid || record?.id}${recordIndex}`">{{ record.type }} : {{ record }}</pre>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="flex flex-row justify-between">
    <div v-if="combinatieIndexes.length">
      <button class="my-2 btn bg-green-500 text-white h-8" @click="handleCombineren"
        :disabled="handleCombineren.loading || combinatieIndexes.length < 2">
        {{ combinatieIndexes.length }} Ritten combineren
      </button>
    </div>
    <div v-if="touched.length">
      <button class="my-2 btn bg-green-500 text-white h-8" @click="handleAllesOpslaan"
        :disabled="handleAllesOpslaan.loading">
        {{ touched.length }} Gewijzigde items opslaan
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import useApi from '@/hooks/useApi'
import isRitRetourDeel from './isRitRetourDeel'
import RowKalender from './RowKalender.vue'
import RowAfspraak from './RowAfspraak.vue'
import UIInputCheckbox from '@/components/UI/Input/Checkbox.vue'
import useKalenderCombinatie from './useKalenderCombinatie'

const props = defineProps({
  showGeocodeStatus: Boolean,
  compact: Boolean,
  annulatiesTonen: Boolean,
  reservaties: {
    type: Array,
    default() {
      return []
    },
  },
  afspraken: {
    type: Array,
    default() {
      return []
    },
  },
})

defineEmits(['date', 'refresh-kalender', 'refresh-afspraken'])
const api = useApi()

const list = computed(() => {
  return (props.reservaties || []).concat(props.afspraken || []).filter(el => !!el).sort((a, b) => {
    if (a.unix == b.unix && a.adressen?.length && b.adressen?.length) {
      return Number(a.adressen[0].is_luchthaven) - Number(b.adressen[0].is_luchthaven)
    }
    return a.unix - b.unix
  })
})

const {
  combinatiesKiezen,
  combinatieKeuze,
  combinatieIndexes,
  handleCombineren,
} = useKalenderCombinatie(list)

const aantalGeannuleerd = computed(() => list.value.filter(el => el?.rit?.is_geannuleerd).length)
const toonGeenData = computed(() => {
  if (list.value.length === 0) return true
  if (!props.annulatiesTonen) return (list.value.length - aantalGeannuleerd.value) === 0
  return false
})

const touched = computed(() => list.value.filter(el => el && el.type === 'KALENDER' && el.touched))

const handleAllesOpslaan = async () => {
  if (handleAllesOpslaan.loading) return
  handleAllesOpslaan.loading = true
  for (const item of touched.value) {
    await handleOpslaan(item)
  }
  handleAllesOpslaan.loading = false
}

const handleOpslaan = async (record) => {
  return api('KALENDER_TOEKENNEN', {
    rit_id: record?.rit?.id,
    is_retour: isRitRetourDeel(record?.rit),
    chauffeurs: record?.chauffeurs,
  }).then(() => {
    delete record.touched
  })
}

const flightHasDuplicate = (vlucht, recordIndex) => {
  if (!vlucht) return false
  const searchVlucht = String(vlucht).replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
  if (!searchVlucht) return false
  const found = list.value.find((el, index) => {
    if (index === recordIndex || !el?.rit?.vlucht) return false
    return searchVlucht === String(el?.rit?.vlucht).replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
  })
  return !!found
}
</script>

<style scoped>
table {
  font-size: 90%;
}

.compact:hidden {
  display: hidden;
}
</style>
