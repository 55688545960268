<template>
  <div
    v-if="record?.rit"
    class="card w-full gap-2 mb-1 border border-gray-300 rounded-sm border-t-4"
    :class="rowClass"
  >
    <div
      v-if="store.getters.hasAdminPermission"
      v-show="!compact"
      class="w-6 flex flex-col gap-2 -ml-2"
    >
      <div
        v-for="(c, index) in record.chauffeurs"
        :key="`${c.id}${index}`"
        class="h-full rounded w-2 mx-1"
        :style="`background-color: ${colorForUserId(c.chauffeur_id)}`"
      ></div>
    </div>

    <div
      class="inline-flex justify-start align-middle content-center mr-2 px-2 pt-1 gap-1 w-48 shrink-1"
      :class="{ 'flex-row lg:flex-col gap-2 items-center': compact, 'flex-col items-start': !compact }"
    >
      <div class="block xl:inline-block w-full xl:w-auto mb-2 xl:mb-0" :class="{ 'mt-4 text-center': !compact }">
        <span class="label-time inline">{{ timeString(record.rit.datum) }}</span>
      </div>
      <TextRitDeel
        v-show="!compact"
        short
        class="font-bold"
        :is-retour="record.rit.is_retour"
        :deel-retour="isRitRetourDeel(record.rit)"
      />
      <button class="text-left text-xs italic text-gray-500 mt-1" v-show="!compact" v-if="record.rit.is_retour" @click="$emit('date', dateString(record.rit.datum_linked))">
        <TextRitDeel short :is-retour="record.rit.is_retour" :deel-retour="!isRitRetourDeel(record.rit)" />
        <span class="block">
          <i class="fas fa-arrow-left" v-if="record.rit.is_retour && isRitRetourDeel(record.rit)"></i>
          <i class="fas fa-arrow-right" v-else-if="record.rit.is_retour && !isRitRetourDeel(record.rit)"></i>
          {{ dateTimeString(record.rit.datum_linked) }}
        </span>
      </button>
      <LabelBlacklisted v-show="!compact" :value="record.blacklisted" />
      <LabelOnbetaaldeRetour v-show="!compact" :rit="record.rit" />
      <PremiumRit v-if="states.isLuxe" v-show="!compact" class="mt-1" />
      <BedrijfThemaLogo class="my-1 mx-auto" :bedrijf="record.rit.bedrijf_thema"></BedrijfThemaLogo>
      <ButtonRitOpvolgingStatus
        v-if="store.getters.hasAdminPermission"
        v-show="!compact"
        class="w-full"
        :disabled="states.isGeannuleerd"
        :status="record.rit.opvolging_status"
        @update:status="handleOpvolgingStatus(record.rit, $event)"
      />
      <ButtonRitCombinatieStatus
        class="w-full"
        v-if="record.rit.combinatie_status > 0"
        :status="record.rit.combinatie_status"
        @update:status="handleCombinatieStatus(record.rit, 0)"
      />
    </div>

    <div class="flex flex-col justify-start items-start w-7/12 gap-1 whitespace-nowrap">
      <LabelRitOpties v-show="!compact" hide-bus class="flex-nowrap text-xs" :rit="record.rit" :verschillend="record.verschillende_opties" />
      <template v-if="store.getters.hasAdminPermission">
        <div class="flex flex-row gap-2 items-center" v-show="!compact">
          <ButtonKalenderDuim :kalender-record="record" @submitted="emit('refresh', true)" />
          <KlantLijn
            class="card gray"
            label="Klant"
            :klant="record.klant || { firstname: '?' }"
          />
          <ButtonAlertPersona :persona="record?.rit?.persona" :disabled="states.isGeannuleerd" />
        </div>
        <KlantLijn
          v-if="record.reiziger"
          v-show="!compact"
          class="card gray"
          label="Reiziger"
          :klant="record.reiziger"
        />
        <FacturatieLijn v-if="record.facturatie" v-show="!compact" label="Facturatie" class="card gray py-0" :facturatie="record.facturatie" />
      </template>
      <div
        class="flex flex-col gap-2 w-full flex-1 items-start"
        :class="{ 'line-through': record.rit.voucher_code }"
      >
        <div class="card gray inline-flex flex-col w-full">
          <GoogleAdressenVisueel :adressen="record.adressen" :show-geocode-status="showGeocodeStatus" />
          <LabelValue v-show="!compact" icon="fas fa-euro-sign" label="Totale rit prijs" class="mt-2" :class="{ 'line-through italic': record?.rit?.is_geannuleerd }">
            <span v-if="record?.rit?.korting > 0 || record?.rit?.korting_promotie > 0" class="text-red-500 line-through font-bold italic pr-1">&euro;&nbsp;{{ Number((record?.rit?.prijs || 0) + (record?.rit?.korting || 0) + (record?.rit?.korting_promotie || 0)).toFixed(2) }} </span>
            <span class="text-green-500 font-bold">&euro;&nbsp;{{ Number(record?.rit?.prijs || 0).toFixed(2) }} </span>
          </LabelValue>
          <LabelValue v-if="record?.rit?.is_geannuleerd" v-show="!compact" icon="fas fa-euro-sign text-red-600 ml-2" label="Annulatie kosten" class="text-red-500">
            <span class="font-bold">&euro;&nbsp;{{ Number(record?.rit?.kosten_annulatie || 0).toFixed(2) }} </span>
          </LabelValue>
          <GoogleFlightStatus
            v-show="!compact"
            :vlucht="record.rit.vlucht"
            :adressen="record.adressen"
            :class="{ 'bg-teal-100 border border-teal-300 rounded inline-block px-1 w-fit': flightHasDuplicate }"
          >Vluchtnummer</GoogleFlightStatus>
        </div>
        <div class="inline-flex flex-row gap-2 flex-1 items-start" v-show="!compact">
          <ButtonAlertOpmerking
            edit
            preview-length="30"
            :opmerking="record?.rit?.opmerking"
            @edit="handleRitOpmerking(record.rit)"
            :disabled="states.isGeannuleerd"
          />
          <ButtonAlertPlanningOptie
            v-if="record?.rit"
            v-model:heen-memo="record.rit.planning_optie_heen"
            v-model:retour-memo="record.rit.planning_optie_retour"
            :is-retour="record.rit.is_retour"
            :hide-heen="isRitRetourDeel(record.rit)"
            :hide-retour="!isRitRetourDeel(record.rit)"
            @edit="handlePlanningOpties(record.rit)"
            :disabled="states.isGeannuleerd"
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col self-start ml-2 mr-1 w-5/12 gap-2">
      <LabelRitOpties v-show="compact" hide-bus class="flex-nowrap self-start" :rit="record.rit" :verschillend="record.verschillende_opties" />
      <div class="inline-flex flex-row gap-1 text-left justify-start" v-show="!compact">
        <ButtonFactuurNodig
          @click="() => record.factuur_nummer ? openFactuur(record.factuur_nummer) : handleFactuur(record.rit, !record.rit.is_factuur_nodig)"
          :value="record.rit.is_factuur_nodig"
          :factuur_nummer="record.factuur_nummer"
          :facturatie_id="record.rit.facturatie_id"
          :journal_id="record.rit.facturatie_journal_id"
          :disabled="states.isGeannuleerd"
          class="enabled-style"
        />
        <ButtonBetaalbewijs
          :disabled="states.isGeannuleerd || record.rit.bedrag_open_saldo > 0"
          @click="handleBetaalbewijs(record.rit)"
          :value="record.rit.is_betalingsbewijs"
          class="enabled-style"
          :class="{
            'cursor-not-allowed': states.isGeannuleerd || record.rit.bedrag_open_saldo > 0,
          }"
        />
        <button
          v-if="store.getters.hasAdminPermission"
          @click="handleExternalReview(record.rit, !record.rit.is_online_recensie)"
          :disabled="states.isGeannuleerd"
          class="btn shadow-none text-xs font-bold rounded-sm enabled-style"
          :class="{
            'cursor-not-allowed': states.isGeannuleerd,
            'bg-gray-200 text-gray-500': !record.rit.is_online_recensie,
            'bg-yellow-300 text-yellow-900': record.rit.is_online_recensie,
          }"
        >
          <i
            class="fas"
            :class="{ 'fa-times': !record.rit.is_online_recensie, 'fa-check': record.rit.is_online_recensie }"
          ></i>
          Review
        </button>
        <button
          v-if="!states.isGeannuleerd && store.getters.hasAdminPermission"
          @click="handleStopMailOpvolging(record.rit)"
          class="btn shadow-none text-xs font-bold rounded-sm justify-self-end enabled-style"
          :class="{
            'bg-gray-200 text-gray-500': !record.rit.is_mail_opvolging_stop,
            'bg-red-300 text-red-900': record.rit.is_mail_opvolging_stop,
          }"
        >
          Opvolging {{ record.rit.is_mail_opvolging_stop ? 'Gestopt' : 'Stop' }}
        </button>
        <button
          v-if="!states.isGeannuleerd && store.getters.hasAdminPermission"
          @click="handleChiron(record.rit, !record.rit.is_chiron)"
          class="btn shadow-none text-sm font-bold rounded-sm enabled-style"
          :class="{
            'cursor-not-allowed italic opacity-50 hover:cursor-not-allowed': !!chironHandlerDisabledReason,
            'bg-red-500 text-white': !record.rit.is_chiron,
            'bg-green-500 text-white': record.rit.is_chiron,
          }"
        >
          <i
            class="text-xs fas"
            :class="{ 'fa-times': !record.rit.is_chiron, 'fa-check': record.rit.is_chiron }"
          ></i>
          Chiron
        </button>
      </div>
      <div class="flex flex-row justify-between" v-show="!compact">
        <div class="inline-flex flex-row gap-1 text-center justify-start">
          <button
            @click="handleMollie(record.rit)"
            class="btn shadow-none text-xs font-bold rounded-sm enabled-style"
            :class="{
              'bg-gray-200 text-gray-500': !record.rit.is_betaalbaar_online,
              'bg-purple-400 text-purple-50': record.rit.is_betaalbaar_online,
            }"
          >
            <i
              class="fas"
              :class="{ 'fa-times': !record.rit.is_betaalbaar_online, 'fa-check': record.rit.is_betaalbaar_online }"
            ></i>
            Mollie
          </button>
          <button
            @click="handleOverschrijving(record.rit)"
            :disabled="states.isGeannuleerd"
            class="btn shadow-none text-xs font-bold rounded-sm enabled-style"
            :class="{
              'cursor-not-allowed': states.isGeannuleerd,
              'bg-gray-200 text-gray-500': !record.rit.is_overschrijving_gewenst,
              'bg-purple-400 text-purple-50': record.rit.is_overschrijving_gewenst,
            }"
          >
            <i
              class="fas"
              :class="{
                'fa-times': !record.rit.is_overschrijving_gewenst,
                'fa-check': record.rit.is_overschrijving_gewenst,
              }"
            ></i>
            Overschrijving
          </button>
          <button
            @click="handleVoucher(record.rit)"
            :disabled="states.isGeannuleerd"
            class="btn text-xs font-bold rounded-sm enabled-style"
            :class="{
              'cursor-not-allowed': states.isGeannuleerd,
              'bg-gray-200 text-gray-500': !record.rit.voucher_gegeven,
              'bg-purple-700 text-white': record.rit.voucher_gegeven,
            }"
          >
            <i class="fas" :class="{ 'fa-gift': record.rit.voucher_gegeven, 'fa-times': !record.rit.voucher_gegeven }"></i>
            {{ record.rit.voucher_gegeven ? `${record.rit.voucher_gegeven}` : 'Compensatie voucher' }}
          </button>
          <button
            v-if="store.getters.hasAdminPermission"
            @click="handleBoete(record.rit, !record.rit.is_boete)"
            class="btn shadow-none text-xs font-bold rounded-sm enabled-style"
            :class="{
              'bg-gray-200 text-gray-500': !record.rit.is_boete,
              'bg-red-700 text-white': record.rit.is_boete,
            }"
          >
            <i
              class="fas"
              :class="{ 'fa-times': !record.rit.is_boete, 'fa-exclamation-triangle': record.rit.is_boete }"
            ></i>
            Boete
          </button>
          <button
            @click="handleTeOntvangen(record.rit, !record.rit.is_te_ontvangen)"
            :disabled="states.isGeannuleerd"
            class="btn shadow-none text-xs font-bold rounded-sm enabled-style"
            :class="{
              'cursor-not-allowed': states.isGeannuleerd,
              'bg-red-500 text-white': !record.rit.is_te_ontvangen,
              'bg-green-500 text-white': record.rit.is_te_ontvangen,
            }"
          >
            <i
              class="fas"
              :class="{ 'fa-times': !record.rit.is_te_ontvangen, 'fa-check': record.rit.is_te_ontvangen }"
            ></i>
            {{ record.rit.is_te_ontvangen ? 'Te ontvangen' : 'Niet ontvangen' }}
          </button>
        </div>
      </div>
      <div class="card gray">
        <span v-if="states.isGeannuleerd" class="text-red-500 font-bold">Rit geannuleerd, geen chauffeur selectie nodig</span>
        <ChauffeursSelectie
          v-else
          v-model="record.chauffeurs"
          :datum="record.rit.datum"
          :blacklist="record.blacklist_chauffeurs || []"
          :omzet-verkeerd="record.rit.korting > 0 || omzetten(record.rit, record.chauffeurs).verkeerd"
          :omzet-chauffeur="record.rit.calculated_omzet_chauffeur"
          :omzet-onderaannemer="record.rit.calculated_omzet_onderaannemer"
          :is-te-ontvangen="record.rit.is_te_ontvangen && record.rit.bedrag_open_saldo > 0"
          :rit_id="record.rit.id"
          :klant_id="record.klant?.id"
          :disabled="nietBewerken"
          :isRetour="isRitRetourDeel(record.rit)"
          :minimum="Math.ceil(record.rit.personen / 8)"
          :is-chiron="!!record.rit.is_chiron"
          @touched="setTouched(true)"
        />
      </div>
      <div class="flex flex-row gap-1 mt-1" v-show="!compact" v-if="store.getters.hasAdminPermission">
        <div v-if="record.rit.is_retour" class="card flex-1 flex items-center gray text-xs">
          <LabelValue :label="`Chauffeurs ${isRitRetourDeel(record.rit) ? 'heenreis' : 'retour'}`">
            <ChauffeurLijst class="block" :chauffeurs="record.chauffeurs_linked" />
            <small
              v-if="record.rit.contract_nummer"
              class="text-purple-500 font-bold"
            >
              Chiron doorgestuurd!
            </small>
          </LabelValue>
        </div>
        <div v-else class="flex-1">&nbsp;</div>
        <div class="card flex-1 flex flex-col items-start justify-center gray">
          <LabelValue icon="fas fa-euro-sign" label="Omzet berekend" class="-ml-1 w-full">
            <div class="flex flex-col justify-between gap-1 w-full px-2">

              <!-- <span class="text-xs">
                <strong>CH:</strong> (&asymp;&nbsp;&euro;&nbsp;{{ formatPrice(omzetten(record.rit, record.chauffeurs).berekendChauffeur) }})
              </span>
              <span class="text-xs">
                <strong>OA:</strong> (&asymp;&nbsp;&euro;&nbsp;{{ formatPrice(omzetten(record.rit, record.chauffeurs).berekendOnderaannemer) }})
              </span> -->

              <div class="text-xs flex flex-row gap-1">
                <strong>CH:</strong>
                <span :class="{ italic: record.rit.korting > 0 }">
                  (&asymp;&nbsp;&euro;&nbsp;{{ formatPrice(omzetBerekend.berekendChauffeur.standaard) }})
                </span>
                <span v-if="record.rit.korting > 0" class="text-green-500">
                  (&asymp;&nbsp;&euro;&nbsp;{{ formatPrice(omzetBerekend.berekendChauffeur.kortingAfgetrokken) }} zonder korting)
                </span>
              </div>

              <div class="text-xs">
                <strong>OA:</strong>
                <span :class="{ italic: record.rit.korting > 0 }">
                  (&asymp;&nbsp;&euro;&nbsp;{{ formatPrice(omzetBerekend.berekendOnderaannemer.standaard) }})
                </span>
                <span v-if="record.rit.korting > 0" class="text-green-500">
                  (&asymp;&nbsp;&euro;&nbsp;{{ formatPrice(omzetBerekend.berekendOnderaannemer.kortingAfgetrokken) }} zonder korting)
                </span>
              </div>

            </div>
          </LabelValue>
          <span v-if="record.rit.korting > 0" class="text-red-500 font-bold block">
            Manuele korting! Omzet nakijken!
          </span>
          <span v-else-if="omzetten(record.rit, record.chauffeurs, { kortingAftrekken: false }).verkeerd" class="text-red-500 font-bold block">
            Omzet nakijken!
          </span>
        </div>
      </div>

      <div class="flex flex-row justify-between" v-if="!compact">
        <div class="flex flex-row gap-1">
          <RitBronDomein v-if="store.getters.hasAdminPermission" :bron="record.rit.bron" :bedrijf_thema="record.rit.bedrijf_thema" />
          <RitPromotie :promotie="record.rit.promotie_code" />
          <span
            class="btn shadow-none text-xs font-bold rounded-sm cursor-not-allowed"
            :class="{
              'bg-gray-200 text-gray-500': !record.rit.is_offerte,
              'bg-yellow-300 text-yellow-900': record.rit.is_offerte,
            }"
          >
            <i class="fas" :class="{ 'fa-times': !record.rit.is_offerte, 'fa-check': record.rit.is_offerte }"></i>
            Offerte
          </span>
        </div>

        <div class="ml-4" v-if="store.getters.hasAdminPermission">
          <button
            @click="handleOnderaanneming(record.rit, record.adressen)"
            :disabled="states.isGeannuleerd"
            class="btn rounded bg-indigo-100 inline-flex justify-center align-middle items-center gap-1 text-xs w-48"
          >
            <i :class="{ 'fas fa-bullhorn': !record.rit.is_onderaanneming_gestuurd, 'fas fa-check': record.rit.is_onderaanneming_gestuurd }"></i> Onderaannemers zoeken
          </button>
        </div>
      </div>
      <small class="shrink-1 block -mt-2 text-purple-500" v-if="store.getters.hasAdminPermission" v-show="!compact && record.rit.domein">{{ record.rit.domein }}</small>
    </div>

    <div class="flex-col flex" :class="{ 'justify-start pt-4': !compact, 'justify-end pb-2': compact }">
      <div
        :class="{
          'inline-flex flex-col gap-1 text-center px-1 w-24': !compact,
          'flex flex-row lg:flex-col gap-1 text-center justify-start px-1 w-full': compact,
        }"
      >
        <button v-if="!nietBewerken" :disabled="states.isGeannuleerd || !record.touched" class="btn bg-green-500 text-white h-8" @click="$emit('opslaan', record)">
          Opslaan
        </button>
        <ButtonRitInfo
          v-show="!compact"
          class="h-8"
          :id="record.rit.id"
          :deel="isRitRetourDeel(record.rit) ? 'retour' : 'heen'"
        />
        <router-link
          v-if="!nietBewerken"
          :disabled="states.isGeannuleerd"
          v-show="!compact" :to="`/ritten/bewerken/${record.rit.id}`" class="btn bg-yellow-400 text-white h-8">
          <i class="fas fa-edit"></i>
        </router-link>
        <button
          class="btn bg-purple-500 text-purple-100 h-8"
          :disabled="states.isGeannuleerd || record.rit.bedrag_ontvangen >= record.rit.prijs"
          @click="openFixedModal('FORM_RIT_BETALING', { rit: record.rit })"
        >
          <i class="fas fa-euro-sign"></i>{{ Math.max(0, Number(record.rit.bedrag_open_saldo)).toFixed(2) }}
        </button>
        <router-link
          v-show="!states.isGeannuleerd && !compact && record.rit.is_chiron"
          :to="`/sign/contract/${record.rit.id}`"
          class="btn warning text-white h-8"
          target="_blank"
          :class="{ 'disabled allow-click': !record.rit.is_getekend }"
        >
          <i
            class="fas"
            :class="{
              'fa-check text-green-400': record.rit.is_getekend,
              'fa-times text-red-400': !record.rit.is_getekend,
            }"
          ></i>
          <i class="fas fa-file-contract"></i> {{ record.rit.contract_nummer || '/' }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue'
import { useVModel } from '@vueuse/core'

import isRitRetourDeel from './isRitRetourDeel'
import { colorForUserId } from '@/functions/colors'
import { formatPrice } from '@/functions/formatNumber'
import { timeString, dateTimeString, dateString, isVerleden } from '@/functions/formatDate'
import isGeocoded from '@/functions/isGeocoded'

import useModal from '@/hooks/useModal'
import { useStore } from 'vuex'

import ChauffeursSelectie from './ChauffeursSelectie.vue'
import PremiumRit from '@/components/UI/Labels/PremiumRit.vue'
import ButtonKalenderDuim from '@/components/UI/Button/ButtonKalenderDuim.vue'
import ButtonRitInfo from '@/components/UI/Button/RitInfo.vue'
import ChauffeurLijst from '@/components/UI/List/ChauffeurLijst.vue'
import RitBronDomein from '@/components/UI/Labels/RitBronDomein.vue'
import RitPromotie from '@/components/UI/Labels/RitPromotie.vue'
import BedrijfThemaLogo from '@/components/UI/Labels/BedrijfThemaLogo.vue'

import ButtonFactuurNodig from './ButtonFactuurNodig.vue'
import ButtonBetaalbewijs from './ButtonBetaalbewijs.vue'
import { useRouter } from 'vue-router'
import { handleRitProp } from '@/functions/apiHandleRitProp'

const KlantLijn = defineAsyncComponent(() => import('@/components/Table/Planning/KlantLijn.vue'))
const FacturatieLijn = defineAsyncComponent(() => import('@/components/Table/Planning/FacturatieLijn.vue'))
// const WagenLijn = defineAsyncComponent(() => import('@/components/Table/Planning/WagenLijn.vue'))
// const PlanningButtons = defineAsyncComponent(() => import('@/components/Table/Planning/Buttons.vue'))
const ButtonAlertPlanningOptie = defineAsyncComponent(() => import('@/components/UI/Button/PlanningOptie.vue'))
const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))
const ButtonAlertPersona = defineAsyncComponent(() => import('@/components/UI/Button/AlertPersona.vue'))
const LabelRitOpties = defineAsyncComponent(() => import('@/components/UI/Labels/RitOpties.vue'))
const LabelValue = defineAsyncComponent(() => import('@/components/UI/Labels/LabelValue.vue'))
const LabelBlacklisted = defineAsyncComponent(() => import('@/components/UI/Labels/Blacklisted.vue'))
const LabelOnbetaaldeRetour = defineAsyncComponent(() => import('@/components/UI/Labels/OnbetaaldeRetour.vue'))
const GoogleFlightStatus = defineAsyncComponent(() => import('@/components/Google/FlightStatus.vue'))
const GoogleAdressenVisueel = defineAsyncComponent(() => import('@/components/Google/AdressenVisueel.vue'))
const TextRitDeel = defineAsyncComponent(() => import('@/components/UI/Text/RitDeel.vue'))
const ButtonRitCombinatieStatus = defineAsyncComponent(() => import('@/components/UI/Button/RitCombinatieStatus.vue'))
const ButtonRitOpvolgingStatus = defineAsyncComponent(() => import('@/components/UI/Button/RitOpvolgingStatus.vue'))

const props = defineProps({
  modelValue: Object,
  compact: Boolean,
  showGeocodeStatus: Boolean,
  flightHasDuplicate: Boolean,
})

const store = useStore()
const router = useRouter()
const [openFixedModal] = useModal({ fixed: true })

const emit = defineEmits(['date', 'refresh', 'opslaan', 'update:modelValue'])
const record = useVModel(props, 'modelValue', emit)

const isZevenDagenVerleden = computed(() => isVerleden(record.value?.rit?.datum, 1000 * 60 * 60 * 24 * 7))
const nietBewerken = computed(() => isZevenDagenVerleden.value && !store.getters.hasAdminPermission)

const states = computed(() => ({
  isGeannuleerd: !!(record.value?.rit?.is_geannuleerd || false),
  isLuxe: !!(record.value?.rit?.kost_luxe_vervoer > 0 || false),
}))

// FLAG:CHIRON
const hasChauffeurStartedShift = (chauffeurs) => !!(chauffeurs?.find(el => el?.shift_id && !!el.shift_start) || false)
// const hasChauffeurShiftChiron = (chauffeurs) => !!(chauffeurs?.find(el => el?.shift_id && !!el.shift_chiron_status) || false)
const chironHandlerDisabledReason = computed(() => {
  if (states.value.isGeannuleerd) {
    return { onmogelijk: true, reason: 'Rit is geannuleerd' }
  }

  const rit = record.value?.rit || {}
  const dv = new Date(rit.datum)
  const dr = rit.datum_linked ? new Date(rit.datum_linked) : null

  if (dv.getTime() < 1724450460000) {
    return { onmogelijk: true, reason: 'Oude rit voor chiron start' }
  }
  if (dr && dr.getTime() < 1724450460000) {
    return { onmogelijk: true, reason: 'Oude rit voor chiron start' }
  }

  if (rit.contract_nummer) {
    return { onmogelijk: true, reason: 'Contract nummer reeds doorgestuurd' }
  }

  if (rit.is_retour && (dv.getFullYear() !== dr.getFullYear() || dv.getMonth() !== dr.getMonth())) {
    return { onmogelijk: false, reason: 'Heen & Terug niet in dezelfde maand' }
  }

  const { chauffeurs, chauffeurs_linked } = record.value || {}
  if (hasChauffeurStartedShift(chauffeurs)) {
    return { onmogelijk: true, reason: 'Chauffeur reeds shift ingevuld' }
  }
  if (hasChauffeurStartedShift(chauffeurs_linked)) {
    return { onmogelijk: true, reason: 'Chauffeur reeds shift ingevuld' }
  }

  return null
})

const rowClass = computed(() => {
  const result = [{
    'flex flex-col lg:flex-row': props.compact,
    'flex': !props.compact,
  }]

  const rit = record.value?.rit || {}

  if (states.value.isGeannuleerd) result.push('border-l-8 border-red-300 bg-red-50')
  else if (!rit.is_chiron && store.getters.hasAdminPermission) result.push('border-l-8 border-purple-300 bg-purple-50')
  else if (states.value.isLuxe) result.push('border-l-8 border-yellow-300 bg-yellow-50')

  return result
})

const handleExternalReview = (rit) => {
  openFixedModal('FORM_RIT_EXTERNAL_REVIEW', { rit })
}

const handleCombinatieStatus = (rit, newValue) =>
  handleRitProp(
    rit,
    newValue,
    `combinatie_status_${isRitRetourDeel(rit) ? 'retour' : 'heen'}`,
    'combinatie_status',
  )

const handleOpvolgingStatus = (rit, newValue) =>
  handleRitProp(
    rit,
    newValue,
    `opvolging_status_${isRitRetourDeel(rit) ? 'retour' : 'heen'}`,
    'opvolging_status',
  )

const handleChiron = (rit, newValue) => {
  const callback = () => handleRitProp(rit, newValue, 'is_chiron')
  if (chironHandlerDisabledReason.value) {
    if (chironHandlerDisabledReason.value?.onmogelijk) {
      alert(`Actie absoluut niet mogelijk: ${chironHandlerDisabledReason.value.reason}`)
      return
    }
    const message = `Actie normaal niet mogelijk:\n${chironHandlerDisabledReason.value.reason}\n\nBen je absoluut zeker dit toch te willen doen?`
    openFixedModal('BEVESTIG_ACTIE', { message, callback, textYes: 'Ja, bevestigen' })
    return
  }
  callback()
}
const handleTeOntvangen = (rit, newValue) => handleRitProp(rit, newValue, 'is_te_ontvangen')
const handleBoete = (rit, newValue) => handleRitProp(rit, newValue, 'is_boete')
const openFactuur = (factuurNummer) => {
  router.push({ name: 'FacturatieFacturenOverzicht', query: { doc: factuurNummer } })
}
const handleFactuur = (rit, factuurNodig) => {
  return handleRitProp(rit, factuurNodig, 'is_factuur_nodig')
}
// const handleFacturatieNummer = (rit) => {
//   openFixedModal('FORM_RIT_FACTUUR', { rit })
// }
const handleVoucher = (rit) => {
  openFixedModal('FORM_RIT_VOUCHER', { rit })
}
const handlePlanningOpties = (rit) => {
  openFixedModal('FORM_RIT_PLANNING_OPTIE', { rit })
}
const handleRitOpmerking = (rit) => {
  openFixedModal('FORM_RIT_OPMERKING', { rit })
}
const handleBetaalbewijs = (rit) => {
  const message = rit.is_betalingsbewijs ? 'Betaalbewijs opnieuw doormailen?' : 'Betaalbewijs doormailen?'
  const callback = () => handleRitProp(rit, true, 'is_betalingsbewijs')
  openFixedModal('BEVESTIG_ACTIE', { message, callback, textYes: 'Ja, doormailen' })
}
const handleMollie = (rit) => {
  const message = rit.is_betaalbaar_online ? 'Mollie betaallink opnieuw doormailen?' : 'Mollie toelaten en betaallink doormailen?'
  const callback = () => handleRitProp(rit, true, 'is_betaalbaar_online')
  openFixedModal('BEVESTIG_ACTIE', { message, callback })
}
const handleOverschrijving = (rit) => {
  const message = rit.is_overschrijving_gewenst ? 'Overschrijving gegevens opnieuw doormailen?' : 'Overschrijving toelaten en bankgegeven doormailen?'
  const callback = () => handleRitProp(rit, true, 'is_overschrijving_gewenst')
  openFixedModal('BEVESTIG_ACTIE', { message, callback })
}
const handleStopMailOpvolging = (rit) => {
  const message = `${rit.is_mail_opvolging_stop ? 'Activeer' : 'Deactiveer'} verzenden van enquetes/geruststelling mails voor deze rit?`
  const callback = () => handleRitProp(rit, !rit.is_mail_opvolging_stop, 'is_mail_opvolging_stop')
  openFixedModal('BEVESTIG_ACTIE', { message, callback })
}
const handleOnderaanneming = (rit, adressen) => {
  const notGeocoded = !!adressen.find(adres => !isGeocoded(adres))
  openFixedModal('FORM_RIT_ONDERAANNEMING', { notGeocoded, modelValue: rit })
}
const setTouched = (touched) => {
  record.value.touched = !!touched
}

const omzetten = (rit, chauffeurs, options = {}) => {
  const { kortingAfgetrokken } = options || {}

  const count = Math.max(chauffeurs.filter(el => el.omzet > 0)?.length || 0, 1)
  const aftrekken = kortingAfgetrokken ? (rit.korting || 0) / (rit.is_retour ? 2 : 1) : 0

  const berekendChauffeur = count === 1
    ? rit.calculated_omzet_chauffeur - aftrekken
    : Math.floor((rit.calculated_omzet_chauffeur - aftrekken) / count)
  const berekendOnderaannemer = count === 1
    ? rit.calculated_omzet_onderaannemer - aftrekken
    : Math.floor((rit.calculated_omzet_onderaannemer - aftrekken) / count)

  const ingevuld = chauffeurs.reduce((prev, next) => prev + next.omzet, 0)
  const foundVerkeerdeOmzet = chauffeurs.find((el) => {
    if (!el || el.omzet <= 0) return true
    if (el.type === 'onderaannemer') return el.omzet != berekendOnderaannemer
    return el.omzet != berekendChauffeur
  })

  return {
    berekendChauffeur,
    berekendOnderaannemer,
    ingevuld,
    verkeerd: !!foundVerkeerdeOmzet,
  }
}

const omzetBerekend = computed(() => {
  const rit = record.value?.rit || {}
  const chauffeurs = record.value?.chauffeurs || []
  return {
    berekendChauffeur: {
      standaard: omzetten(rit, chauffeurs, { kortingAfgetrokken: false }).berekendChauffeur,
      kortingAfgetrokken: omzetten(rit, chauffeurs, { kortingAfgetrokken: true }).berekendChauffeur,
    },
    berekendOnderaannemer: {
      standaard: omzetten(rit, chauffeurs, { kortingAfgetrokken: false }).berekendOnderaannemer,
      kortingAfgetrokken: omzetten(rit, chauffeurs, { kortingAfgetrokken: true }).berekendOnderaannemer,
    },
  }
})
</script>
